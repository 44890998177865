import React, { ReactComponentElement } from "react";
import ReactDOM from "react-dom";
import { Routes, Route } from "react-router-dom";
import { pageUrls } from "./common/constants";
import MainPage from "./pages/MainPage";
import ConfigPage from "./pages/ConfigPage";
import AuthPage from "./pages/AuthPage";
import ForbiddenPage from "./pages/ForbiddenPage";
import NotFoundPage from "./pages/NotFoundPage";

const AppRoutes: React.FC<{}> = () => {
    return (
        <Routes>
            <Route path={pageUrls.URL_BASE} element={<MainPage />} />
            <Route path={pageUrls.URL_AUTH} element={<AuthPage />} />
            <Route path={pageUrls.URL_CONFIG} element={<ConfigPage />} />
            <Route path={pageUrls.URL_FORBIDDEN} element={<ForbiddenPage />} />
            <Route path={pageUrls.URL_NOT_FOUND} element={<NotFoundPage />} />
        </Routes>
    );
};

export default AppRoutes;
