import * as React from "react";
import { useEffect, useState } from "react";
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from "formik";
import { isNullOrEmpty, showSuccess } from "../common/utils";
import { login as saveLogin_to_local_storage } from "../common/authUtils";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";

import ComponentInfo from "../components/ComponentInfo";
import Placeholder from "../components/Placeholder";
import { pageUrls } from "../common/constants";
import { useAuth } from "../common/authUtils";
import AuthService from "../services/AuthService";

interface SignInValues {
    password: string;
    login: string;
}

const AuthPage: React.FC = (props) => {
    const [isUpdating, setIsUpdating] = useState<boolean>(false); // когда внутренний запрос идёт

    return (
        <>
            {isUpdating ? <Placeholder isUpdating={isUpdating} /> : null}
            <AuthPageContent {...props} setIsUpdating={setIsUpdating} />
        </>
    );
};

interface AuthPageContentProps {
    setIsUpdating: React.Dispatch<React.SetStateAction<boolean>>;
}

const AuthPageContent: React.FC<AuthPageContentProps> = (props) => {
    const { t } = useTranslation(["auth_page"]);

    const validate = (values: SignInValues) => {
        let errors: any = {};

        if (isNullOrEmpty(values.password)) {
            errors.password = t("password_is_required");
        }

        if (isNullOrEmpty(values.login)) {
            errors.login = t("login_is_required");
        }

        return errors;
    };

    let navigate = useNavigate();

    const { isAuthenticated } = useAuth();
    if (isAuthenticated) {
        navigate(pageUrls.URL_BASE);
    }

    const [clickedSubmitButton, setClickedSubmitButton] = useState<boolean>(false);

    return (
        <div className={"sign-in-container"}>
            <Formik
                initialValues={
                    {
                        password: "",
                        login: "",
                    } as SignInValues
                }
                onSubmit={async (
                    values: SignInValues,
                    {
                        setSubmitting,
                        resetForm,
                        setFieldValue,
                    }: FormikHelpers<SignInValues>
                ) => {
                    const service = new AuthService();
                    props.setIsUpdating(true);
                    let result = await service.login(
                        values.login,
                        values.password
                    );
                    props.setIsUpdating(false);

                    if (!result.hasErrors) {
                        showSuccess(t("logged_successfully"));
                        saveLogin_to_local_storage(
                            result.value.access_token,
                            result.value.username,
                            result.value.isAdmin
                        );
                        
                        navigate(pageUrls.URL_BASE)
                    }
                    setSubmitting(false);
                }}
                // onSubmit={(values, actions) => {
                //     const result = {
                //         ...values,
                //         clickedSubmitButton: clickedSubmitButton == SubmitButton.LogIn ? "login" : "create new account"
                //     };
                //     alert(JSON.stringify(result, null, 2));
                //     actions.setSubmitting(false);
                // }}
                validate={validate}
            >
                {/* @ts-ignore */}
                {({ values, getFieldMeta }) => {
                    const validClass = (name: string) => {
                        const meta = getFieldMeta(name);
                        return meta.error && meta.touched ? "error-input" : "";
                    };
                    return (
                        <Form>
                            <div className="form-group mt-5">
                                <Field
                                    placeholder={t("login_input_phrase")}
                                    type={"login"}
                                    className={`form-control ${validClass(
                                        "login"
                                    )}`}
                                    id="login"
                                    name="login"
                                    autoComplete="off"
                                />
                                <ErrorMessage
                                    name={"login"}
                                    className={"error-message"}
                                    component={"div"}
                                />
                            </div>
                            <div className="form-group mt-3">
                                <Field
                                    placeholder={t("password_input_phrase")}
                                    type={"password"}
                                    className={`form-control ${validClass(
                                        "password"
                                    )}`}
                                    id="password"
                                    name="password"
                                    autoComplete="off"
                                />
                                <ErrorMessage
                                    name={"password"}
                                    className={"error-message"}
                                    component={"div"}
                                />
                            </div>
                            <Button
                                onClick={(e) => setClickedSubmitButton(true)}
                                className={"login-btn mt-3 btn-md float-end"}
                                variant="primary"
                                type="submit"
                            >
                                {t("login_btn")}
                            </Button>

                            {/* <div className={"mt-3"}>
                <ComponentInfo values={values} />
              </div> */}
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
};

export default AuthPage;
