import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../common/authUtils";
import { pageUrls } from "../common/constants";
import { showErrors, isDef, showSuccess, isNullOrEmpty } from "../common/utils";
import Placeholder from "../components/Placeholder";
import RanobeModel from "../models/RanobeModel";
import ZakupHistoryModel from "../models/ZakupHistoryModel";
import ZakupHistoryService from "../services/ZakupHistoryService";
import ZakupService from "../services/ZakupService";
import { RiFolderDownloadLine, RiFolderUploadLine, RiFileList2Line } from "react-icons/ri";
import { AiOutlineSave } from "react-icons/ai";
import { BsClipboardCheck } from "react-icons/bs";
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from "formik";
import RenameRanobeModel from "../models/RenameRanobeModel";
import { Button } from "react-bootstrap";
import ComponentInfo from "../components/ComponentInfo";
import Creatable from 'react-select/creatable';
import Highlighter from 'react-highlight-words';



const MainPage: React.FC = (props) => {
    const [isUpdating, setIsUpdating] = useState<boolean>(false); // когда внутренний запрос идёт
    const [isFetching, setIsFetching] = useState<boolean>(true); // когда инициализация страницы происходит
    const [zakupHistories, setZakupHistories] = useState<Array<ZakupHistoryModel>>([]); // коллекция историй
    const [currentRenameRanobeFiles, setCurrentRenameRanobeFiles] = useState<Array<RenameRanobeModel>>([]); // коллекция ранобэ
    const [allFoldersYaDisk, setAllFoldersYaDisk] = useState<Array<string>>([]); // список всех папок в яндекс диске по маппингам, нужен для выпадающего сипска
    const { isAuthenticated } = useAuth();
    let navigate = useNavigate();


    const zakupHistoryService = new ZakupHistoryService();
    const zakupService = new ZakupService();

    const fetchZakupHistoryAsync = async (last: number = 10) => {
        let zakupHistoriesResult = await zakupHistoryService.getZakupHistories(last);

        if (!zakupHistoriesResult.hasErrors) {
            setZakupHistories(zakupHistoriesResult.value);
        } else {
            showErrors(...zakupHistoriesResult.errors);
        }
    };

    const fetchFilesForCurrentZakuAsync = async (last: number = 10) => {
        let filesForCurrentZakupResult = await zakupService.getFilesForCurrentZakup();

        if (!filesForCurrentZakupResult.hasErrors) {
            setCurrentRenameRanobeFiles(filesForCurrentZakupResult.value);
        } else {
            showErrors(...filesForCurrentZakupResult.errors);
        }
    };

    const fetchAllFoldersYaDiskAsync = async () => {
        let allFoldersYaDiskResult = await zakupService.getAllFoldersYaDisk();

        if (!allFoldersYaDiskResult.hasErrors) {
            setAllFoldersYaDisk(allFoldersYaDiskResult.value);
        } else {
            showErrors(...allFoldersYaDiskResult.errors);
        }
    };

    const appendZakupHistory = (new_zakup_history: ZakupHistoryModel) => {
        setZakupHistories([...zakupHistories, new_zakup_history])
    }

    useEffect(() => {
        if (isAuthenticated) {
            const from_auth = localStorage.getItem(
                "from_auth"
            );
            if(from_auth === "true"){
                localStorage.setItem("from_auth", "false")
                window.location.reload();
            }

            fetchZakupHistoryAsync();
            fetchAllFoldersYaDiskAsync()
            fetchFilesForCurrentZakuAsync().then(()=>{setIsFetching(false)},()=>{})
        } else {
            navigate(pageUrls.URL_AUTH);
        }
    }, []);

    return (
        <>
            {isUpdating ? <Placeholder isUpdating={isUpdating} /> : null}
            {isFetching ? (
                <Placeholder />
            ) : (
                <MainPageContent
                    {...props}
                    setIsUpdating={setIsUpdating}
                    zakupHistories={zakupHistories}
                    currentZakupFiles={currentRenameRanobeFiles}
                    allFoldersFromYD={allFoldersYaDisk}
                    appendZakupHistory={appendZakupHistory}
                />
            )}
        </>
    );
};

interface MainPageContentProps {
    setIsUpdating: React.Dispatch<React.SetStateAction<boolean>>;
    zakupHistories: Array<ZakupHistoryModel>;
    currentZakupFiles: Array<RenameRanobeModel>;
    allFoldersFromYD: Array<string>;
    appendZakupHistory: (new_zakup_history: ZakupHistoryModel) => void
}

interface CurrentZakupValues {
    files: Array<RenameRanobeModel>
}

const MainPageContent: React.FC<MainPageContentProps> = (props) => {
    const { t } = useTranslation("main_page");
    const [clickedSubmitButton, setClickedSubmitButton] = useState<boolean>(false);
    const validate = (values: CurrentZakupValues) => {
        let errors: any = {
            files:[]
        };

        for (let index = 0; index < values.files.length; index++) {
            let err = {
                "renamed_filename": "",
                "mapping" :{
                    "destination_mapping_path": ""
                }
            }
            const element = values.files[index];
            if (isNullOrEmpty(values.files[index].renamed_filename)) {
                err["renamed_filename"] = t('validation_messages.required');
            }

            if (isDef(values.files[index].mapping) && (!isDef(values.files[index].mapping.destination_mapping_path) || isNullOrEmpty(values.files[index].mapping.destination_mapping_path))){
                err.mapping.destination_mapping_path = t('validation_messages.destination_mapping_path_required')
            }
            
            if (err.renamed_filename === "" && err.mapping.destination_mapping_path === ""){
                errors.files.push({})
            } else {
                errors.files.push(err)
            }
        }

        if(isAnyError(errors)){
            return errors
        }

        return {};
    };

    const isAnyError = (errors:any) => {
        for (let i = 0; i < errors.files.length; i++) {
            if (JSON.stringify(errors.files[i]) !== JSON.stringify({})){
                return true
            }
        }

        return false
    }


    interface RanobeLineComponentProps {
        ranobe: RenameRanobeModel,
        index: number,
        validClass: (name:string)=>string,
        allFoldersFromYD: Array<string>,
        setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
    }

    const customStyles = {
        //@ts-ignore
        control: (provided, state) => ({
          ...provided,
          background: "#fff",
          borderColor: "#9e9e9e",
          minHeight: "20px",
          height: "20px",
          boxShadow: state.isFocused ? null : null,
          fontSize: 12
        }),
        //@ts-ignore
        valueContainer: (provided, state) => ({
          ...provided,
          height: "20px",
          padding: "0 6px",
          fontSize: 12,
          marginTop: "-4px",
        }),
        //@ts-ignore
        input: (provided, state) => ({
          ...provided,
          margin: "0px",
          fontSize: 12

        }),
        //@ts-ignore
        indicatorSeparator: (state) => ({
          display: "none",
          fontSize: 12

        }),
        //@ts-ignore
        indicatorsContainer: (provided, state) => ({
          ...provided,
          height: "20px",
          fontSize: 12

        }),
        //@ts-ignore
        option: (provided, state) => ({
            ...provided,
        
            fontSize: 12,
            padding: "2px 6px",

          }),
      };

    interface SelectOption{
        label: string,
        value: string
    }

    const isContainsPath = (s:string, arr:Array<SelectOption>) => {
        for (var i = 0; i < arr.length; i++){
            if(arr[i].value === s){
                return true
            }
        }
        return false
    }
    
    const RanobeLineComponent:React.FC<RanobeLineComponentProps> = (props) => {
        const [isEditMode, setEditMode] = useState<boolean>(false)
        const [isDestinationPathNew, setIsDestinationPathNew] = useState<boolean>(false)

        let options = ([...props.allFoldersFromYD]).map(
            (v)=> {
                return {
                    label: v,
                    value: v
                } as SelectOption
            });

        debugger
        if(isDef(props.ranobe.mapping) 
         && isDef(props.ranobe.mapping?.errors)
         && isDef(props.ranobe.mapping?.errors?.several_mappings) 
         && isDef(props.ranobe.mapping?.errors?.several_mappings?.probable_mappings)){
             //@ts-ignore
            for (let index = 0; index < props.ranobe.mapping?.errors?.several_mappings?.probable_mappings?.length ?? 0; index++) {
                const element = props.ranobe.mapping?.errors?.several_mappings?.probable_mappings[index];
                if(!isContainsPath(element?.toString() ?? "", options)){
                    debugger
                    options.push({
                        label: element?.toString() ?? "",
                        value: element?.toString() ?? ""
                    })
                }
                
            }
         }

        if(isDef(props.ranobe.mapping) && isDef(props.ranobe.mapping?.errors) && isDef(props.ranobe.mapping?.errors?.path_existanse_for_destination_mappings) && isDef(props.ranobe.mapping?.destination_mapping_path)){
            options.push({
                label: props.ranobe.mapping?.destination_mapping_path,
                value: props.ranobe.mapping?.destination_mapping_path
            } as SelectOption)
        }


        const onChange = (option:any) => {
            const isNew:boolean = isDef(option.__isNew__) && option.__isNew__
            setIsDestinationPathNew(isNew)

            props.setFieldValue(
                `files[${props.index}].mapping.destination_mapping_path`,
                isNullOrEmpty(option) ? "" : option.value.includes(props.ranobe.mapping.nebosvod_path) ? option.value : props.ranobe.mapping.nebosvod_path + "/" + option.value
            );
        };

        //@ts-ignore
        const formatOptionLabel = ({label}, {inputValue}) => {
            const escapeRegExp = (input:any) => {
                const source = typeof input === 'string' || input instanceof String ? input : '';
                return source.replace(/[-[/\]{}()*+?.,\\^$|#\s]/g, '\\$&');
            }

            return (
              <Highlighter
                highlightClassName="search_highlight"
                searchWords={[escapeRegExp(inputValue)]}
                textToHighlight={label}
              />
            );
          }

        return (
            <div className="form-group mt-1 ranobe-row">
                <div className="original-ranobe-title">{props.ranobe.original_filename}</div>
                {
                    isEditMode
                        ? <Field autoComplete="off" className={`form-control smaller-input editable-renamed-ranobe-title ${props.validClass(`files[${props.index}].renamed_filename`)}`} name={`files[${props.index}].renamed_filename`} />
                        : <div className="renamed-ranobe-title" onDoubleClick={() => setEditMode(!isEditMode)}>{props.ranobe.renamed_filename}</div>
                }
                <ErrorMessage name={`files[${props.index}].renamed_filename`} className={"error-message"} component={"div"} />
                <Creatable
                                defaultValue={options.find((option) => option.value === props.ranobe.mapping.destination_mapping_path)}
                                className={`basic-single ${props.validClass(`files[${props.index}].mapping.destination_mapping_path`)}`}
                                classNamePrefix="select"
                                onChange={onChange}
                                isDisabled={false}
                                isClearable={true}
                                isSearchable={true}
                                name={`files[${props.index}].mapping.destination_mapping_path`}
                                options={options}
                                styles={customStyles}
                                formatOptionLabel={formatOptionLabel}
                                formatCreateLabel={userInput => `${t('create_new_label_start_str')} ${props.ranobe.mapping.nebosvod_path + "/"}${userInput}`}
                            />
                <ErrorMessage name={`files[${props.index}].mapping.destination_mapping_path`} className={"error-message"} component={"div"} />
                {
                    isDestinationPathNew
                    ? <div className="created-new-ditectory-title">{`${t('will_created_new_ditectory')} ${props.ranobe.mapping.destination_mapping_path}`}</div>
                    : ""
                }
                {/* <ComponentInfo mapping={props.ranobe}></ComponentInfo> */}
            </div>
        )
    }

    //@ts-ignore
    const onKeyDown = (keyEvent) => {
        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
          keyEvent.preventDefault();
        }
      }

    return (
        <div className={"main-page-container"}>
            <div className="main-title">{t("current_zakup_header")}</div>
            <div className="current-zakup-block">
            <>
                { isDef(props.currentZakupFiles) && props.currentZakupFiles.length > 0 
                    ?
                    <Formik
                    initialValues={
                        {
                            files: props.currentZakupFiles
                        } as CurrentZakupValues
                    }
                    onSubmit={async (
                        values: CurrentZakupValues,
                        {
                            setSubmitting,
                            resetForm,
                            setFieldValue,
                        }: FormikHelpers<CurrentZakupValues>
                    ) => {
                        props.setIsUpdating(true);

                        const zakupService = new ZakupService();
                        props.setIsUpdating(true);
                        let result = await zakupService.createZakup(
                            values.files
                        );

                        if (!result.hasErrors) {
                            showSuccess(t("success_zacup_created"));
                            setSubmitting(false);
                            props.setIsUpdating(false);

                            window.location.reload();
                        } else {
                            showErrors(...result.errors)
                        }
                    }}
                    validate={validate}
                >
    
                    {({ values, getFieldMeta, errors, setFieldValue}) => {
                        const validClass = (name: string) => {
                            const meta = getFieldMeta(name);
                            return meta.error && meta.touched ? "error-input" : "";
                        };
                        return (
                            <Form onKeyDown={onKeyDown}>
                            {
                                isDef(values.files) && values.files.length>0 
                                    ? values.files.map((ranobe, index) => <RanobeLineComponent key={ranobe.original_filename} setFieldValue={setFieldValue} allFoldersFromYD={props.allFoldersFromYD} index={index} ranobe={ranobe} validClass={validClass} />)
                                    : ""
                            }
                            
                            <Button
                                onClick={(e) => setClickedSubmitButton(true)}
                                className={"login-btn mt-3 btn-md"}
                                variant="success"
                                type="submit"
                            >
                                {t("create_zekup")}
                            </Button>
    
                            {/* <div className={"mt-3"}>
                                <ComponentInfo errors={errors} />
                            </div> */}
                        </Form>
                            
                        );
                    }}
                </Formik>
                    : "Добавьте файлы в папку закупа и обновите страницу...   :)"
                }
            </>
            
            </div>
            <div className="zakup-histories-block">
                {props.zakupHistories.length > 0
                    ? props.zakupHistories.map((zakupHistory) => (
                          <ZakupHistory key={zakupHistory.id} zakupHistory={zakupHistory} />
                      ))
                    : ""}
            </div>
        </div>
    );
};

interface ZakupHistoryProps {
    zakupHistory: ZakupHistoryModel;
}

const ZakupHistory: React.FC<ZakupHistoryProps> = ({ zakupHistory }) => {
    const { t } = useTranslation("main_page");
    const [showHistory, setShowHistory] = useState(false);
    const { isAdmin } = useAuth();

    const formattedDateTime = new Date(zakupHistory.created).toLocaleString(t("time_lang"), {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
    });

    const showLogs = (logs: string) => {
        console.log(logs);
    };

    const copyToClipboard = async (ranobe: RanobeModel[]) => {
        let allText =
            new Date(zakupHistory.created).toLocaleString(t("time_lang"), {
                year: "numeric",
                month: "long",
                day: "numeric",
            }) + "\n\n";
        if (isDef(ranobe) && ranobe.length > 0) {
            ranobe
                .sort((a, b) => (a.title > b.title ? 1 : -1))
                .forEach((r) => {
                    allText += r.title + "\n";
                });
        }

        if ("clipboard" in navigator) {
            return await navigator.clipboard.writeText(allText).then(
                () => showSuccess(t("success_copied_to_clipboard_message")),
                () => showErrors(t("failed_copied_to_clipboard_message"))
            );
        } else{
                showErrors(t("failed_copied_to_clipboard_message"));
        }
    };

    return (
        <div className="zakup-history-block" key={zakupHistory.id}>
            <div className="created-date">{formattedDateTime} [{zakupHistory.creator}]</div>

            {isDef(zakupHistory.ranobe) && zakupHistory.ranobe.length > 0 ? (
                <div className="ranobes-list">
                    {zakupHistory.ranobe
                        .sort((a, b) => (a.title > b.title ? 1 : -1))
                        .map((r) =>
                            !showHistory ? (
                                <div key={r.title}>{r.title}</div>
                            ) : (
                                <div key={r.title}>
                                    <div>{r.title}</div>
                                    <div className="move-path">
                                        {">"} {r.move_path}
                                    </div>
                                </div>
                            )
                        )}
                </div>
            ) : (
                ""
            )}

            <div className="icons-ranobe-history-container">
                {isDef(zakupHistory.ranobe) && zakupHistory.ranobe.length > 0 ? (
                    <>

                        { isAdmin ? (
                            <>
                                <div className="copy-icon">
                                    <a href={"#"} onClick={(e) => {e.preventDefault(); e.stopPropagation(); copyToClipboard(zakupHistory.ranobe)}}>
                                        <BsClipboardCheck title={t("copy_to_clipboard")} size={30} />
                                    </a>
                                </div>
                            </>
                        ) : (
                            ""
                        )}
                        
                        <div className="move-history-icon">
                            <a href={"#"} onClick={(e) => {e.preventDefault(); e.stopPropagation(); setShowHistory(!showHistory)}}>
                                {!showHistory ? (
                                    <RiFolderDownloadLine title={t("show_move_history")} size={30} />
                                ) : (
                                    <RiFolderUploadLine title={t("hide_move_history")} size={30} />
                                )}
                            </a>
                        </div>
                    </>
                ) : (
                    ""
                )}
                { isAdmin ? (
                    <>
                        <div className="logs-icon">
                            <a href={"#"} onClick={() => showLogs(zakupHistory.logs)}>
                                <RiFileList2Line title={t("show_logs")} size={30} />
                            </a>
                        </div>
                    </>
                ) : (
                    ""
                )}
            </div>
        </div>
    );
};

export default MainPage;
