import { toast } from "react-toastify";
import { ParsedQuery } from "query-string";

// NodeJs process.
declare var process: any;

/**
 * Is server prerendering by NodeJs.
 * There can't be any DOM elements such as: window, document, etc.
 */
export function isNode(): boolean {
    return (
        typeof process === "object" &&
        process.versions &&
        !!process.versions.node
    );
}

/**
 * Show error messages on page.
 * @param messages
 */
export function showErrors(...messages: string[]): void {
    messages.forEach((x) => {
        if (!Array.isArray(x)) {
            toast.error(x);
        } else {
            (x as any).forEach((y: string) => toast.error(y));
        }
    });
}

/**
 * Show information message on page.
 * @param message
 */
export function showInfo(message: string): void {
    toast.info(message);
}

/**
 * Show success information message on page.
 * @param message
 */
export function showSuccess(message: string): void {
    toast.success(message);
}

/**
 * Clone object.
 * @param object input object.
 */
export function clone<T>(object: T): T {
    return JSON.parse(JSON.stringify(object));
}

export function isObjectEmpty(obj: any): boolean {
    for (let key in obj) {
        if (obj.hasOwnProperty(key)) return false;
    }
    return true;
}

/**
 * Paginate an array for the client side.
 * @param array input array.
 * @param pageNumber page number.
 * @param limitPerPage entries per page.
 */
export function paginate<T>(
    array: T[],
    pageNumber: number,
    limitPerPage: number
): T[] {
    let rowOffset = Math.ceil((pageNumber - 1) * limitPerPage);
    return array.slice(rowOffset, rowOffset + limitPerPage);
}

/**
 * Check if item is null or undefined
 * @param item item which will be check.
 */
export function isDef(item: any): boolean {
    return typeof item !== "undefined" && item !== null;
}

/**
 * Check if item is null, undefined, or empty
 * @param item item which will be check.
 */
export function isNullOrEmpty(item: any): boolean {
    return typeof item === "undefined" || item === null || item === "";
}

/**
 * Checks if object contains property
 * @param obj object in which will be check existing property.
 * @param prop property checked property.
 */
export function isPropExists(obj: object, prop: string): boolean {
    for (let key in obj) {
        if (key === prop) {
            return true;
        }
    }
    return false;
}

/**
 * Gets all params from get query string
 * @param url url query string.
 */
export function getParamsFromUrl(url: string): ParsedQuery {
    const queryString = require("query-string");
    return queryString.parseUrl(url).query;
}

/**
 * remove item from array, returns clone array without element by selected index
 * @param array array.
 * @param index element's index which you want to delete.
 */
export function removeItemFromArray<T>(
    array: Array<T>,
    index: number
): Array<T> {
    let arrayCopy = clone(array);
    arrayCopy.splice(index, 1);
    return arrayCopy;
}

/**
 * random elements shuffle in Array
 * @param array array.
 * @param index element's index which you want to delete.
 */
export function shuffleArray<T>(array: Array<T>): Array<T> {
    let arrayCopy = clone(array);
    let j, temp;
    for (let i = arrayCopy.length - 1; i > 0; i--) {
        j = Math.floor(Math.random() * (i + 1));
        temp = arrayCopy[j];
        arrayCopy[j] = arrayCopy[i];
        arrayCopy[i] = temp;
    }
    return arrayCopy;
}
