import * as React from "react";
import { useState } from "react";
import {
    Collapse,
    Container,
    Navbar,
    NavbarToggler,
    NavItem,
    NavLink,
} from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import "./NavMenu.css";
import { pageUrls } from "../common/constants";
import { logout, useAuth } from "../common/authUtils";
import { showSuccess } from "../common/utils";
import { useTranslation } from "react-i18next";

type NavMenyProps = {};

const NavMenu: React.FC<NavMenyProps> = (props) => {
    const location = useLocation();

    const { t } = useTranslation(["nav_menu"]);

    const [state, setState] = useState({
        isOpen: false,
    });
    const { isAuthenticated, currentUserName } = useAuth();

    const toggle = () => {
        setState({
            isOpen: !state.isOpen,
        });
    };

    return (
        <header>
            <Navbar
                className="navbar-expand-sm navbar-toggleable-sm border-bottom box-shadow mb-3"
                light
            >
                <Container>
                    <NavbarToggler onClick={toggle} className="mr-2" />
                    <Collapse
                        className="d-sm-inline-flex flex-sm-row-reverse"
                        isOpen={state.isOpen}
                        navbar
                    >
                        <ul className="navbar-nav flex-grow">
                            {location.pathname !== pageUrls.URL_BASE ? (
                                <NavItem>
                                    <NavLink
                                        tag={Link}
                                        className="text-dark"
                                        to={pageUrls.URL_BASE}
                                    >
                                        {t("main_page_title")}
                                    </NavLink>
                                </NavItem>
                            ) : (
                                ""
                            )}
                            {/* {location.pathname !== pageUrls.URL_CONFIG ? (
                                <NavItem>
                                    <NavLink
                                        tag={Link}
                                        className="text-dark"
                                        to={pageUrls.URL_CONFIG}
                                    >
                                        {t("configuration_page_title")}
                                    </NavLink>
                                </NavItem>
                            ) : (
                                ""
                            )} */}
                            <NavItem>
                                <NavLink
                                    onClick={(e) => {
                                        logout();
                                        showSuccess(t("logged_out"));
                                    }}
                                    tag={Link}
                                    className="text-danger logout-nav-link"
                                    to={pageUrls.URL_AUTH}
                                >
                                    {t("logout_title")}{" "}
                                    {isAuthenticated
                                        ? `(${currentUserName})`
                                        : ""}
                                </NavLink>
                            </NavItem>
                        </ul>
                    </Collapse>
                </Container>
            </Navbar>
        </header>
    );
};

export default NavMenu;
