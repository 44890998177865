import { ServiceBase } from "../core/service-base";
import Result from "../core/result";
import RenameRanobeModel from "../models/RenameRanobeModel";
import ZakupHistoryModel from "../models/ZakupHistoryModel";

export default class ZakupHistoryService extends ServiceBase {
    public async getFilesForCurrentZakup(): Promise<Result<Array<RenameRanobeModel>>> {
        const result = await this.requestJson<Array<RenameRanobeModel>>({
            url: `api/zakup/files-for-current-zakup`,
            method: "GET"
        });
        return result;
    }

    public async getAllFoldersYaDisk(): Promise<Result<Array<string>>> {
        const result = await this.requestJson<Array<string>>({
            url: `api/zakup/all-folders-ya-disk`,
            method: "GET"
        });
        return result;
    }

    public async createZakup(renobeList: Array<RenameRanobeModel>): Promise<Result<ZakupHistoryModel>> {
        const result = await this.requestJson<ZakupHistoryModel>({
            url: `api/zakup/create`,
            method: "POST",
            data: renobeList
        });
        return result;
    }

    // public async updateSettingsForCurrentLoggedUser(settings: ZakupHistoryModel): Promise<Result<SettingsModel>> {
    //     const result = await this.requestJson<SettingsModel>({
    //         url: `api/settings/edit`,
    //         method: "POST",
    //         data: {
    //             ...settings,
    //         },
    //     });
    //     return result;
    // }
}
