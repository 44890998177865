import * as React from "react";
import { Container } from "reactstrap";
import NavMenu from "./NavMenu";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { useLocation } from "react-router-dom";
import { pageUrls } from "../common/constants";

export default (props: { children?: React.ReactNode }) => {
    const location = useLocation();
    const isShowNavMenu = location.pathname !== pageUrls.URL_AUTH;
    return (
        <React.Fragment>
            {isShowNavMenu && <NavMenu />}
            <Container>{props.children}</Container>
            <ToastContainer autoClose={1000} />
        </React.Fragment>
    );
};
