import { ServiceBase } from "../core/service-base";
import Result from "../core/result";
import ZakupHistoryModel from "../models/ZakupHistoryModel";

export default class ZakupHistoryService extends ServiceBase {
    public async getZakupHistories(last: number): Promise<Result<Array<ZakupHistoryModel>>> {
        const result = await this.requestJson<Array<ZakupHistoryModel>>({
            url: `api/zakuphistory/get-zakup-history`,
            method: "GET",
            data: { last },
        });
        return result;
    }

    // public async updateSettingsForCurrentLoggedUser(settings: ZakupHistoryModel): Promise<Result<SettingsModel>> {
    //     const result = await this.requestJson<SettingsModel>({
    //         url: `api/settings/edit`,
    //         method: "POST",
    //         data: {
    //             ...settings,
    //         },
    //     });
    //     return result;
    // }
}
