export const pageUrls = {
    URL_BASE: "/",
    URL_AUTH: "/auth",
    URL_CONFIG: "/config",

    URL_NOT_FOUND: "/not-found",
    URL_FORBIDDEN: "/forbidden",
};

export const localStorageConstants = {
    USER_DATA_STORAGE_NAME: "userData",
};
